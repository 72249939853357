import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { header } from '../utils/Request';
import { neuronClientMarginUrl, neuronPayoutUrl, tokenUrl, tokenUser, tokenpass } from '../utils/Urls';

export default function ClientPayoutRequestModal({ show, handleClose, clientId, clientName, accountNo, accountName }) {
    const [marginAvailable, setMarginAvailable] = useState('');
    const [amountToWithdraw, setAmountToWithdraw] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (clientId) {
            getAvailableMarginData();
        }
    }, [clientId]);

    const getAvailableMarginData = async () => {
        console.log("Getting Client Available Margin");
        try {
            let url = `${neuronClientMarginUrl}client_code=${encodeURIComponent(clientId)}`;
            console.log(url);
            const response = await axios.get(url);
            if (response.status === 200 && response.data.status === "success") {
                const balanceList = response.data.result.result.BalanceList;
                if (balanceList && balanceList.length > 0) {
                    const cashAvailableValue = balanceList[0].limitObject.RMSSubLimits.cashAvailable;
                    console.log(cashAvailableValue)
                    setMarginAvailable(cashAvailableValue);
                }
            } else {
                // console.error('Error: Invalid response structure', );
                setErrorMessage('Failed to fetch margin data', response);
            }
        } catch (error) {
            // console.error('Error fetching margin data:', error);
            setErrorMessage('Failed to fetch margin data', error);
        }
    };

    const getToken = async () => {
        try {
            let body = {
                "username": tokenUser,
                "password": tokenpass
            };
            let response = await axios.post(tokenUrl, body);
            let token = response.data.access;
            return token;
        } catch (error) {
            // console.error('Error fetching token:', error);tokenUrl, tokenUser, tokenpass
            throw error;
        }
    };

    const requestPayout = async () => {
        console.log("Payout request FUnction started...")
        try {
            let available = parseFloat(marginAvailable.toString())
            let withdraw = parseFloat(amountToWithdraw.toString())
            if (withdraw > available) {
                setErrorMessage('Withdrawal Value Should be lessthan Available Value...');
            } else {
                let body = {
                    "clientCode": clientId,
                    "clientName": clientName,
                    "amount": amountToWithdraw,
                    "account_number": accountNo,
                    "bank_name": accountName,
                    "source": "SUB",
                    "event":"NEU_REQUEST"
                }
                const gettt = await getToken();
                let sheaders = {
                    'Authorization': `Bearer ${gettt}`,
                    'Content-Type': 'application/json'
                };
                let data = await axios.post(neuronPayoutUrl, body, { headers: sheaders })
                if (data.status === "success") {
                    setSuccessMessage('Your Payout request placed Successfully...')
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    setErrorMessage(data.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        } catch (error) {
            setErrorMessage('Failed to fetch margin data', error);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
        }
    }

    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName} tabIndex="-1" role="dialog" id="clientPayoutRequestModal">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"><span id="updateClientTitle">Payout Request</span></h5>
                        <button type="button" className="close" onClick={handleClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xl-12" style={{ display: 'none' }}>
                                <div className="form-group">
                                    <label htmlFor="mClientIdLabel">Client Code</label>
                                    <input type="text" className="form-control" id="mClientId" value={clientId} disabled />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12" style={{ display: 'none' }}>
                                <div className="form-group">
                                    <label htmlFor="mClientNameLabel">Client Name</label>
                                    <input type="text" className="form-control" id="PayClientName" value={clientName} disabled />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <label htmlFor="marginAvailableLabel">Net Margin Available</label>
                                    <input type="text" className="form-control" id="clientMarginAvailableId" value={marginAvailable} disabled />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <label htmlFor="amountToWithdrawLabel">Amount to Withdraw</label>
                                    <input type="number" className="form-control" value={amountToWithdraw} onChange={(e) => setAmountToWithdraw(e.target.value)} id="amountToWithdrawId" />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12 text-center">
                                <button className='bg-green-500 mx-3 mt-8 p-2 text-white rounded font-medium text-sm ' onClick={requestPayout}>Request Payout</button>
                                <p id="payoutRequestSuccessTxt" style={{ color: '#34a853' }} className="mt-1">{successMessage}</p>
                                <p id="payoutRequestErrorTxt" style={{ color: '#EA5455' }} className="mt-1">{errorMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
